import SectionLayout from '@/layouts/SectionLayout'
import { motion } from 'framer-motion'
export default function ShowcaseSectionIntro() {
    return (
    <SectionLayout className="relative mb-4 md:mb-8">
      <motion.h4
        initial={{ filter: "blur(5px)" }}
        whileInView={{ filter: "blur(0px)" }}
        transition={{ duration: 1, delay: 0.3 }}
        viewport={{ once: true, amount: 1.0, margin: "0px 0px -50px 0px" }}
        className="x-text-gradient-metal-down mb-4 text-3xl md:text-4xl md:leading-normal"
      >
        Introducing the Render Platform....
      </motion.h4>
    </SectionLayout>
  )
}